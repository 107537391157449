/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"
import { useState, useEffect } from "react"
import Link from "./link"
import Icon from "../assets/icon"
import { colors } from "../theme"
import { ab } from "../theme"

const StickyContactButton = ({
  currentLanguage,
  currentMode,
  contactDetails,
  emailText,
  phoneText,
  careerStickyButtonText,
  careerStickyButtonLink,
}) => {
  if (!emailText && !phoneText) return null

  const [showIcons, setShowIcons] = useState(false)
  const [showPhoneNo, setShowPhoneNo] = useState(false)
  const [showApplyLink, setShowApplyLink] = useState(false)

  let email, phone, contact, hideIcon, showEmailonly
  const career = currentMode === "career"

  const ContactIcon = () => <Icon icon="envelope" style={{ fill: "#fff" }} />
  const PhoneIcon = () => <Icon icon="phone" style={{ fill: "#fff" }} />

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowIcons(false)
      setShowPhoneNo(false)
      setShowApplyLink(false)
    }, 4000)

    return () => clearTimeout(timer)
  }, [showIcons, showPhoneNo, showApplyLink])

  const handlePhoneNo = (e) => {
    e.preventDefault()
    setShowPhoneNo(true)
  }

  if (contactDetails) {
    email = contactDetails.email
    phone = contactDetails.phone
    contact = contactDetails.contact
    hideIcon = contactDetails.hideIcon
  }

  if (!email && !contact) return null

  const renderLink = (contact, linkText) => {
    const linkProps = {
      sx: {
        display: "flex",
        flexDirection: ab({ _: "row-reverse", md: "row" }),
      },
    }

    if (contact.linktype === "story") {
      return (
        <Link
          to={`/${contact.cached_url}`}
          language={currentLanguage}
          {...linkProps}
        >
          <ContactIcon />
          <span sx={{ whiteSpace: "nowrap" }}> {linkText}</span>
        </Link>
      )
    }

    if (contact.linktype === "email") {
      return (
        <a href={`mailto:${contact.url}`} {...linkProps}>
          <ContactIcon />
          <span sx={{ whiteSpace: "nowrap" }}> {linkText}</span>
        </a>
      )
    }

    return (
      <a href={contact.url} {...linkProps}>
        <ContactIcon />
        <span sx={{ whiteSpace: "nowrap" }}> {linkText}</span>
      </a>
    )
  }

  const renderContact = (contactDetails, linkText) => {
    if (contactDetails.contact) {
      return renderLink(contactDetails.contact, linkText)
    }

    return (
      <a
        href={`mailto:${contactDetails.email}`}
        sx={{
          display: "flex",
          flexDirection: ab({ _: "row-reverse", md: "row" }),
        }}
      >
        <ContactIcon />
        <span sx={{ whiteSpace: "nowrap" }}> {linkText}</span>
      </a>
    )
  }

  showEmailonly = true

  const PhonePopUp = () => (
    <div
      className="phone-popup"
      sx={{
        position: "fixed",
        top: ab({ _: "90px", md: "auto" }),
        bottom: ab({ _: " auto", md: "100px" }),
        right: ab({ _: " 40px", md: "150px" }),
        height: "64px",
        lineHeight: "64px",
        color: "#fff",
        padding: "0px 10px",
        borderRadius: "30px",
        width: "300px",
        background: showPhoneNo ? colors.modes.group.primary : "#ffffff00 ",
        animationName: showPhoneNo ? "fadeInPopUp" : "fadeOutPopUp",
        animationFillMode: "forwards",
        animationDuration: "300ms",
        textAlign: "center",
        "@keyframes fadeOutPopUp": {
          "0%": { opacity: 1, display: "block", zIndex: 100 },
          "30%": { opacity: 0.5 },
          "99%": { display: "block", zIndex: 100 },
          "100%": { opacity: 0, display: "none", zIndex: 0 },
        },
        "@keyframes fadeInPopUp": {
          "0%": { opacity: 0, display: "none" },
          "1%": { display: "block" },
          "30%": { opacity: 0.5 },
          "100%": { opacity: 1, zIndex: 100 },
        },
        "@media(min-width:1500px)": { right: "0", bottom: 0 },
      }}
    >
      <div
        sx={{
          display: showPhoneNo ? "flex" : "none",
          textAlign: "center",
          a: { color: "#fff", textDecoration: "none" },
        }}
      >
        <div sx={{ flex: 10 }}>
          {" "}
          Tel.<a href={"tel:" + phone}>{phone}</a>
        </div>
        <div
          onClick={() => setShowPhoneNo(false)}
          sx={{
            width: "18px",
            height: "18px",
            borderRadius: "100%",
            display: "flex",
            justifyContent: "center",
            border: "solid 1px #fff",
            alignItems: "center",
            mr: "10px",
            mt: "10px",
            button: { all: "unset", fontSize: "10px", cursor: "pointer" },
          }}
        >
          <button>X</button>
        </div>
      </div>
    </div>
  )

  return (
    <div sx={{ display: hideIcon ? "none" : "" }}>
      <div
        sx={{
          position: "fixed",
          top: ab({ _: "90px", md: "auto" }),
          bottom: ab({ _: " auto", md: "100px" }),
          right: ab({ _: " 40px", md: "150px" }),
          display: career ? "none" : "flex",
          justifyContent: "flex-end",
          height: "64px",
          width: showIcons ? "320px" : "64px",
          overflow: "hidden",
          borderRadius: "40px",
          transition: "all 500ms linear",
          zIndex: 10,
          "@media(min-width:1500px)": {
            right: "50%",
            transform: "translateX(575px)",
          },
        }}
      >
        <div
          sx={{
            ml: ab({ _: "0", md: "0%" }),
            color: "#fff",
            height: "64px",
            borderRadius: "40px",
            display: "flex",
            justifyContent: "flex-end",
            ".withText": {
              px: "10px",
              animationName: showIcons ? "fadeInAni" : "fadeOutAni",
              animationDuration: "600ms",
              animationFillMode: "forwards",
              zIndex: 11,
            },
            span: { m: "10px" },
            a: {
              textDecoration: "none",
              color: "#fff",
              alignItems: "center",
              height: "64px",
              svg: { mx: "10px" },
            },
          }}
        >
          <div
            className="withText email"
            sx={{
              borderRadius: ab({ _: " 0 40px 40px 0", md: " 40px  0 0 40px " }),
              background: colors.modes.group.primary,
              order: ab({ _: 2, md: 1 }),
            }}
          >
            {renderContact(contactDetails, emailText)}
          </div>
          <div
            className="withText phone"
            sx={{
              background: ab({ _: colors.modes.group.primary, md: "#83a3c3" }),
              borderRadius: ab({ _: "40px 0 0 40px", md: " 0 40px 40px 0 " }),
              order: ab({ _: 1, md: 2 }),
              display: showEmailonly ? "none" : "flex",
            }}
          >
            <a
              onClick={handlePhoneNo}
              href="#"
              sx={{
                display: "flex",
                flexDirection: ab({ _: "row-reverse", md: "row" }),
                display: ab({ _: "none", md: "flex" }),
              }}
            >
              <span>{phoneText}</span>
              <PhoneIcon />
            </a>
            <a
              href={"tel:" + phone}
              sx={{
                display: "flex",
                flexDirection: ab({ _: "row-reverse", md: "row" }),
                display: ab({ _: "flex", md: "none" }),
              }}
            >
              <span>{phoneText}</span>
              <PhoneIcon />
            </a>
          </div>
          <div
            onClick={() => setShowIcons(!showIcons)}
            sx={{
              animationName: showIcons ? " fadeOutChatIcon" : "fadeInChatIcon",
              animationDuration: "600ms",
              animationFillMode: "forwards",
              position: "relative",
              height: "64px",
              width: "64px",
              position: "absolute",
              right: showIcons ? "-60px" : "0",
              zIndex: "100",
              "@keyframes fadeInChatIcon": {
                "0%": {
                  opacity: 0,
                  display: "none",
                  transform: "translateX(200px)",
                },
                "1%": { display: "block" },
                "30%": { opacity: 0 },
                "100%": { opacity: 1, transform: "translateX(00px)" },
              },
              "@keyframes fadeOutChatIcon": {
                "0%": { opacity: 1, transform: "translateX(-280px)" },
                "40%": { opacity: 0.0 },
                "99%": { display: "block" },
                "100%": {
                  opacity: 0,
                  display: "none",
                  transform: "translateX(0px)",
                },
              },
              "& >div": {
                height: "64px",
                width: "64px",
                borderRadius: "40px",
                cursor: "pointer",
                background: colors.modes.group.primary,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              },
            }}
          >
            <div sx={{ display: ab({ _: "none", md: "block" }) }}>
              <ContactIcon />
            </div>
            <div
              sx={{
                display: ab({ _: "block", md: "none" }),
                background: showEmailonly ? "#fff" : colors.modes.group.primary,
                p: showEmailonly ? 0 : " 10px 0 0 12px",
              }}
            >
              {showEmailonly ? <ContactIcon /> : <PhoneIcon />}
            </div>
          </div>
        </div>
        <PhonePopUp />
      </div>
      <div
        className="career-button"
        sx={{
          position: "fixed",
          top: "auto",
          bottom: ab({ _: " 80px", md: "100px" }),
          right: ab({ _: " 40px", md: "150px" }),
          height: "64px",
          lineHeight: "64px",
          color: "#fff",
          borderRadius: "30px",
          width: showApplyLink ? "250px" : "64px",
          background: colors.modes.career.primary,
          transition: " width 400ms ",
          textAlign: "center",
          display: career ? "flex" : "none",
          justifyContent: "center",
          ".show-text": { display: "block", zIndex: 11 },
          ".hide-text": { display: "none" },
          ".hide-icon": { maxWidth: 0 },
          "show-icon": { position: "relative", bottom: "-1px" },
          "@media(min-width:1500px)": {
            right: "50%",
            transform: "translateX(575px)",
          },
        }}
      >
        <div
          className={showApplyLink ? "text show-text" : "text hide-text"}
          sx={{ overflow: "hidden" }}
        >
          <Link
            to={careerStickyButtonLink && careerStickyButtonLink.id}
            language={currentLanguage}
            sx={{
              color: "#fff",
              textDecoration: "none",
              overflow: "hidden",
              fontSize: "24px",
              fontWeight: "800",
            }}
          >
            {careerStickyButtonText}
          </Link>
          <div
            onClick={() => setShowApplyLink(false)}
            sx={{
              width: "24px",
              height: "24px",
              borderRadius: "100%",
              border: "solid 1px #fff",
              position: "absolute",
              top: "-4px",
              right: "-4px",
              p: "2px",
              background: colors.modes.career.primary,
              cursor: "pointer",
              svg: {
                display: "inherit",
                "@supports (-webkit-touch-callout: none)": {
                  position: "absolute",
                  top: "2px",
                  left: "2px",
                },
              },
            }}
          >
            <Icon style={{ fill: "#fff" }} icon="close" />
          </div>
        </div>
        <div
          className={showApplyLink ? "hide-icon" : "show-icon"}
          onClick={() => setShowApplyLink(true)}
          sx={{
            height: "64px",
            width: "64px",
            borderRadius: "40px",
            cursor: "pointer",
            overflowY: "hidden",
            position: "relative",
            bottom: "-1px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            sx={{
              color: "#fff",
              textDecoration: "none",
              overflow: "hidden",
              fontSize: currentLanguage === "de" ? "10px" : "15px",
              fontWeight: "normal",
              lineHeight: currentLanguage === "de" ? "12px" : "20px",
              textTransform: "uppercase",
              display: "inline-block",
              fontWeight: "bold",
            }}
          >
            {careerStickyButtonText}
          </div>
        </div>
      </div>
    </div>
  )
}

StickyContactButton.propTypes = {
  currentMode: PropTypes.oneOf([
    "",
    "group",
    "pharma",
    "food",
    "environment",
    "career",
    "construction",
    "medical",
    "lifeScience",
    "agroChemical",
    "sustainabilityServices",
    "none",
  ]),
  contactDetails: PropTypes.object,
  emailText: PropTypes.string,
  phoneText: PropTypes.string,
  careerStickyButtonText: PropTypes.string,
  careerStickyButtonLink: PropTypes.string,
}

export default StickyContactButton
