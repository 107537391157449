/** @jsx jsx */
import { jsx } from "theme-ui"
import { useState, useContext, useRef } from "react"
import PropTypes from "prop-types"
import { navigate } from "gatsby"

import ShopContext from "../context/shop-context"
import { FormName } from "./form/formContext"
import CaptchaContext from "../context/captchaContext"
import { ab } from "../theme"
import Button from "./button"

const Form = ({
  formName,
  heading,
  subHeading,
  successHeading,
  successMessage,
  successBtnText = "Weiter",
  redirectAfterSubmit,
  children,
  authenticationRequired = false,
  currentLanguage,
  anchorName,
  captchaRequired,
}) => {
  const { getUser } = useContext(ShopContext)
  const { getCaptcha } = useContext(CaptchaContext)
  const user = getUser()

  const [submitted, setSubmitted] = useState(false)
  const formRef = useRef(null)

  const handleSubmit = (e) => {
    e.preventDefault()

    const form = formRef.current // Access the form element
    const authorization =
      user.token && authenticationRequired
        ? {
            Authorization: `Bearer ${user.token.access_token}`,
          }
        : {}

    // Disable the submit button
    const submitButton = form.querySelector('button[type="submit"]')
    submitButton.disabled = true

    if (captchaRequired && getCaptcha() === false) {
      submitButton.disabled = false
      return
    }

    const formData = new FormData(e.target)
    const plainFormData = Object.fromEntries(formData.entries())
    const formDataJsonString = JSON.stringify(plainFormData)

    const lamdaUrl = "/.netlify/functions/mailer"

    fetch(lamdaUrl, {
      method: "POST",
      headers: {
        accept: "application/json",
        ...authorization,
      },
      body: formDataJsonString,
    })
      .then((response) => {
        if (response.ok) {
          setSubmitted(true)
        } else {
          alert(response.statusText)
        }
      })
      .catch((error) => alert(error))

    // submitButton.disabled = false;
  }

  const returnAfterSubmit = () => {
    if (redirectAfterSubmit) {
      navigate(redirectAfterSubmit)
    } else {
      setSubmitted(false)
    }
  }

  const uniqueFormName = `${formName}-${currentLanguage}`

  if (!submitted) {
    return (
      <div
        id={anchorName ? anchorName : ""}
        sx={{
          variant: "spacingContainer",
        }}
      >
        <div
          sx={{
            maxWidth: "1100px",
            variant: "guc.form",
            pr: ab({ lg: "60px" }),
            ".form-submit-button": {
              flexGrow: ab({ _: "", md: captchaRequired ? 1 : "" }),
              margin: "auto 0",
              textAlign: captchaRequired ? "right" : "",
            },
          }}
        >
          <div>
            <div
              sx={{
                fontSize: "34px",
                letterSpacing: 1,
              }}
            >
              {heading}
            </div>
            <div sx={{ marginBottom: 3 }}>{subHeading}</div>
          </div>

          <form
            name={uniqueFormName}
            method="POST"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            onSubmit={handleSubmit}
            ref={formRef}
          >
            <input type="hidden" name="form_name" value={uniqueFormName} />
            <input type="hidden" name="bot-field" />
            <input type="hidden" name="language" value={currentLanguage} />

            <FormName.Provider value={uniqueFormName}>
              {children}
            </FormName.Provider>
          </form>
        </div>
      </div>
    )
  } else {
    return (
      <div
        sx={{
          variant: "spacingContainer",
        }}
      >
        <div
          sx={{
            maxWidth: ab({ lg: "50%" }),
            variant: "guc.successMessage",
          }}
        >
          <h1
            sx={{
              px: ab({ _: "0 !important", md: "0 !important" }),
            }}
          >
            {successHeading}
          </h1>
          {successMessage}
          <p>
            <Button onClick={returnAfterSubmit} buttonText={successBtnText} />
          </p>
        </div>
      </div>
    )
  }
}

Form.propTypes = {
  formName: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  subHeading: PropTypes.string.isRequired,
  successHeading: PropTypes.string.isRequired,
  successMessage: PropTypes.node.isRequired,
  successBtnText: PropTypes.string,
  authenticationRequired: PropTypes.bool,
  currentLanguage: PropTypes.string.isRequired,
  children: PropTypes.node,
}

export default Form
