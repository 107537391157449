module.exports = [{
      plugin: require('../../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../gatsby-theme-gba/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.gba-group.us","siteId":"51206750-9261-4142-a130-916b481f595f","branch":"master","algoliaSearchApiKey":"e1eb4969201ad5370ef02aa5ea4d6619","algoliaAdminApiKey":"f33779ec8fbef04c37358dfbb8291824","algoliaApplicationId":"9NKPYEEKB4","algoliaQueries":[{"query":"{\n    pages: allStoryblokEntry {\n      edges {\n        node {\n          objectID: id\n          name\n          slug\n          full_slug\n          lang\n          content\n          published_at\n          internal {\n            contentDigest\n            type\n            owner\n          }\n        }\n      }\n    }\n\n  }","indexName":"GBA-US","settings":{"attributesToSnippet":["name:20","content:50","description:50"]}}],"algoliaChunkSize":10000,"context":"production"},
    },{
      plugin: require('../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
