/** @jsx jsx */
import { jsx } from "theme-ui"
import { useState, useEffect } from "react"

import PropTypes from "prop-types"

import { TransformLinkUri } from "./link"
import Link from "./link"
import { ab } from "../theme"
import { colors } from "../theme"

const LanguageSwitcher = ({
  currentLanguage,
  allLanguage,
  currentURL,
  seoLinks,
  locationText,
  locationLink,
}) => {
  const [languages, setLanguages] = useState(allLanguage)
  let locationURL

  useEffect(() => {
    setLanguages(allLanguage)
  }, [allLanguage])

  let toggleActiveClass = () => {
    document.querySelector(".languageSwitcherList").onclick = function () {
      this.classList.toggle("active")
    }
  }

  useEffect(() => {
    let listUl = document.querySelector(".languageSwitcherList")

    listUl.addEventListener("click", toggleActiveClass())
    return () => {
      listUl.removeEventListener("click", toggleActiveClass())
    }
  })

  const links = () => {
    if (languages !== undefined) {
      const langArry = Object.values(languages)

      const deLink = (l, to) => (
        <Link
          to={to}
          sx={{
            color: "text",
            border: 0,
            py: 0,
            px: 1,
            textDecoration: "none",
            backgroundColor: "transparent",
          }}
        >
          <span
            sx={{
              display: "inline-block",
              fontWeight: currentLanguage === l ? "bold" : "normal",
              textTransform: "capitalize",
            }}
          >
            {l}
          </span>
        </Link>
      )

      return langArry.map((l, i) => {
        const to = TransformLinkUri(currentURL, "", l === "" ? "de" : l)

        return (
          <li key={i} data-cy-identifier={"language-switch-" + l}>
            {i === 0 ? deLink("de", to) : deLink(l, to)}
          </li>
        )
      })
    }
  }
  locationURL = locationLink
    ? TransformLinkUri(locationLink.id, "", currentLanguage)
    : ""

  return (
    <div
      sx={{
        display: "flex",
        flexDirection: ab({ _: "row", md: "column" }),
      }}
    >
      {locationText && (
        <div
          sx={{
            order: ab({ _: "1", md: "0" }),
            ml: ab({ _: "10px", md: "0" }),
            mb: ab({ _: "0", md: "4px" }),
            textAlign: "center",
          }}
        >
          <Link
            to={locationURL}
            language={currentLanguage}
            sx={{ color: colors.text, textDecoration: "none" }}
          >
            {locationText}
          </Link>
        </div>
      )}
      <ul className="languageSwitcherList">
        <li
          sx={{
            textTransform: "capitalize",
            fontWeight: "bold",
          }}
        >
          {currentLanguage}
        </li>
        {links()}
      </ul>
    </div>
  )
}

LanguageSwitcher.propTypes = {
  currentURL: PropTypes.string.isRequired,
  allLanguage: PropTypes.object,
  locationLink: PropTypes.object,
  locationText: PropTypes.string,
}

LanguageSwitcher.defaultProps = {
  currentURL: "",
  currentLanguage: "de",
}

export default LanguageSwitcher
